//
// vector-maps.scss
//

.jqvmap-label {
    background: $dark;
    font-family: $font-family-secondary;
    font-size: $font-size-base;
    padding: 0.3rem 0.6rem;
    color: $light;
}

.jqvmap-zoomin, .jqvmap-zoomout {
    width: 24px;
    height: 24px;
    line-height: 18px;
}

.jqvmap-zoomout {
    top: 40px;
}