

//
// bootstrap-timepicker.scss
//

.bootstrap-timepicker-widget {
    table {
        td {
            input {
                height: 32px;
                width: 32px;
                color: $white;
                border: none;
                background-color: $primary;
                border-radius: 50%;
            }

            a{
                color: $gray-400;
                &:hover {
                    background-color: transparent;
                    border: 1px solid transparent;
                    color: $primary;
                }
            }
        }
    }
}
