// 
// page-title.scss
//

.page-title-box {
    position: relative;
    padding: 0 30px;
    margin: 0 -30px 30px -30px;
    background-color: $light;

    .page-title {
        font-size: 1.125rem;
        margin: 0;
        line-height: 50px;
        color: $gray-900;
    }
    .page-title-right {
        float: right;
    }

    .breadcrumb {
        margin-bottom: 0;
        padding: 14px 0;
        a {
            color: $text-muted;
        }
    }
}


@include media-breakpoint-down(sm) {
    .page-title-box {
        .page-title {
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 70px;
        }
        .breadcrumb {
            display: none;
        }
    }
}

@media (max-width: 640px) {
    .page-title-box {
        .page-title-right {
            display: none;
        }
    }
}

@media (max-width: 419px) {
    .page-title-box .breadcrumb {
        display: none;
    }
}